/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import axios from "../Axios/axiosConfig";
// import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
// project imports
import { SET_USER } from "../store/actions";
// project imports
import MainCard from "../components/cards/MainCard";
import Transitions from "../components/extended/Transitions";
import User1 from "../assets/images/users/user-round.svg";

// constant
import LogoutIcon from "@mui/icons-material/Logout";

import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);
  const [userDetails, setUserDetails] = useState({
    name: localStorage.getItem("name") || "",
    imgUrl: localStorage.getItem("imgUrlvalue") || "",
  });

  useEffect(() => {
    if (userDetails.name != "" && userDetails.imgUrl != "") {
      dispatch({
        type: SET_USER,
        user: {
          name: userDetails.name,
          imgUrl: userDetails.imgUrl,
        },
      });
    }
  }, [userDetails]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    googleLogout();
    setUserDetails((prev) => {
      return {
        name: "",
        imgUrl: "",
      };
    });
    dispatch({
      type: SET_USER,
      user: {
        name: "",
        imgUrl: "",
      },
    });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Avatar
        src={userDetails.imgUrl != "" ? userDetails.imgUrl : User1}
        sx={{
          ...theme.typography.mediumAvatar,
          margin: "8px 0 8px 8px !important",
          cursor: "pointer",
        }}
        alt="Just paste it profile"
        ref={anchorRef}
        onClick={handleToggle}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        color="inherit"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 1 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        {userDetails.name !== "" ? (
                          <>
                            <Typography variant="h6">Welcome,</Typography>
                            <Typography
                              component="span"
                              variant="h6"
                              sx={{ fontWeight: 400 }}
                            >
                              {userDetails.name}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            variant="h6"
                            color="textSecondary"
                            align="center"
                          >
                            Sign in to avoid filling out the captcha.
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                  {userDetails.name == "" && (
                    <ListItemButton
                      sx={{
                        borderRadius: `${customization.borderRadius}px`,
                        m: 1.5,
                        width: "100%",
                        maxWidth: 250,
                        minWidth: 200,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                        "& .MuiListItemButton-root": {
                          mt: 0.5,
                        },
                      }}
                      selected={selectedIndex === 4}
                    >
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          let userDetailResponse = jwtDecode(
                            credentialResponse?.credential
                          );
                          let nameValue = userDetailResponse?.name || prev.name;
                          let imgUrlvalue =
                            userDetailResponse?.picture || prev.imgUrl;

                          localStorage.setItem("name", nameValue);
                          localStorage.setItem("imgUrlvalue", imgUrlvalue);

                          setUserDetails((prev) => ({
                            ...prev,
                            name: nameValue,
                            imgUrl: imgUrlvalue,
                          }));

                          dispatch({
                            type: SET_USER,
                            user: {
                              name: nameValue,
                              imgUrl: imgUrlvalue,
                            },
                          });

                          // Asynchronous backend API call using Axios
                          axios
                            .post("users/add", {
                              "username": nameValue,
                              "email": userDetailResponse.email
                            })
                            .then((response) => {})
                            .catch((error) => {
                              // Handle backend API errors (optional)
                              console.error("Some error occured");
                            });
                        }}
                        onError={() => {
                          console.log("Login Failed");
                        }}
                        useOneTap
                      />
                    </ListItemButton>
                  )}
                  {userDetails.name != "" && (
                    <ListItemButton
                      sx={{
                        borderRadius: `${customization.borderRadius}px`,
                        m: 1.5,
                        width: "100%",
                        maxWidth: 250,
                        minWidth: 200,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                        "& .MuiListItemButton-root": {
                          mt: 0.5,
                        },
                      }}
                      selected={selectedIndex === 4}
                      onClick={handleLogout}
                    >
                      <ListItemIcon>
                        <LogoutIcon stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">Logout</Typography>
                        }
                      />
                    </ListItemButton>
                  )}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
