import React from "react";
import { Grid, Typography, Paper, Box, Divider } from "@mui/material";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined"; // Assuming this is the icon you want to use
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Books from "../assets/images/products/Books.png";
import Hosting from "../assets/images/products/Hosting.png";
import Deals from "../assets/images/products/Deals.png";
import Graphics from "../assets/images/products/Graphics.png";
import HostingCard from "./products/HostingCard";
import AmazonCards from "./products/AmazonCards";
import { useLocation, Link } from "react-router-dom";
import BookCards from "./products/BookCards";
import GraphicCard from "./products/GraphicCard";
const iconWrapperStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "60px",
  height: "60px",
  borderRadius: "50%",
  background: "#673ab7", // Your primary color
  cursor: "pointer",
  transition: "background-color 0.3s ease",
};

const BetterResponseRow = () => {
  const [clicked, setClicked] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState("Deals");

  const handleItemClick = () => {
    // Toggle clicked state
    setClicked(!clicked);
  };
  const location = useLocation();
  return (
    <>
      <Grid container spacing={2} my={2}>
        {/* Deals */}
        <Grid key={"Deals"} item xs={6} sm={6} md={4} lg={3}>
          <Box
            p={2}
            textAlign="center"
            borderRadius={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center" // Center horizontally
            sx={{
              cursor: "pointer", // Add pointer cursor
              backgroundColor:
                selectedItem === "Deals" ? "#EDEDED" : "transparent", // Add selection effect
              transition: "background-color 0.3s ease", // Add transition effect
              "&:hover": {
                backgroundColor: "#EDEDED", // Add hover effect
              },
            }}
            onClick={() => setSelectedItem("Deals")} // Handle selection
          >
            <img
              src={Deals} // Make sure Books contains the correct image URL
              alt="Deals" // Provide a descriptive alt text relevant to the image
              height={50}
              width={50}
              loading="lazy" // Optional: Use lazy loading for better performance
            />
            <Typography variant="body1">Deals</Typography>
          </Box>
        </Grid>

        {/* Books */}
        <Grid key={"Books"} item xs={6} sm={6} md={4} lg={3}>
          <Box
            p={2}
            textAlign="center"
            borderRadius={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center" // Center horizontally
            sx={{
              cursor: "pointer", // Add pointer cursor
              backgroundColor:
                selectedItem === "Books" ? "#EDEDED" : "transparent", // Add selection effect
              transition: "background-color 0.3s ease", // Add transition effect
              "&:hover": {
                backgroundColor: "#EDEDED", // Add hover effect
              },
            }}
            onClick={() => setSelectedItem("Books")} // Handle selection
          >
            <img
              src={Books} // Make sure Books contains the correct image URL
              alt="Books" // Provide a descriptive alt text relevant to the image
              height={50}
              width={50}
              loading="lazy" // Optional: Use lazy loading for better performance
            />
            <Typography variant="body1">Books</Typography>
          </Box>
        </Grid>

        {/* Graphics */}
        <Grid key={"Graphics"} item xs={6} sm={6} md={4} lg={3}>
          <Box
            p={2}
            textAlign="center"
            borderRadius={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center" // Center horizontally
            sx={{
              cursor: "pointer", // Add pointer cursor
              backgroundColor:
                selectedItem === "Graphics" ? "#EDEDED" : "transparent", // Add selection effect
              transition: "background-color 0.3s ease", // Add transition effect
              "&:hover": {
                backgroundColor: "#EDEDED", // Add hover effect
              },
            }}
            onClick={() => setSelectedItem("Graphics")} // Handle selection
          >
            <img
              src={Graphics} // Make sure Books contains the correct image URL
              alt="Graphics" // Provide a descriptive alt text relevant to the image
              height={50}
              width={50}
              loading="lazy" // Optional: Use lazy loading for better performance
            />
            <Typography variant="body1">Graphics</Typography>
          </Box>
        </Grid>

        {/* Hosting */}
        <Grid key={"Hosting"} item xs={6} sm={6} md={4} lg={3}>
          <Box
            p={2}
            textAlign="center"
            borderRadius={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center" // Center horizontally
            sx={{
              cursor: "pointer", // Add pointer cursor
              backgroundColor:
                selectedItem === "Hosting" ? "#EDEDED" : "transparent", // Add selection effect
              transition: "background-color 0.3s ease", // Add transition effect
              "&:hover": {
                backgroundColor: "#EDEDED", // Add hover effect
              },
            }}
            onClick={() => setSelectedItem("Hosting")} // Handle selection
          >
            <img
              src={Hosting} // Make sure Books contains the correct image URL
              alt="Hosting" // Provide a descriptive alt text relevant to the image
              height={50}
              width={50}
              loading="lazy" // Optional: Use lazy loading for better performance
            />
            <Typography variant="body1">Hosting</Typography>
          </Box>
        </Grid>
      </Grid>
        <Divider sx={{ mb: 3 }} />
        {selectedItem == "Hosting" ? (
          <HostingCard />
        ) : selectedItem == "Deals" ? (
          <AmazonCards />
        ) : selectedItem == "Books" ? (
          <BookCards />
        ) : selectedItem == "Graphics" ? (
          <GraphicCard />
        ) : (
          <></>
        )}
    </>
  );
};

export default BetterResponseRow;
