import React, { useState } from "react";
// import Button from '@mui/material/Button';
import Modal from "@mui/material/Modal";
import {
  TextField,
  Grid,
  Button,
  Box,
  Container,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Rating,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "../Axios/axiosConfig";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
// material-ui
// import { useTheme } from '@mui/material/styles';

export default function CustomLinkModel({ isOpen, toggleLinkModel }) {
  const theme = useTheme();
  const [urlInput, setUrlInput] = useState("");
  const [searchResponse, setResarchResponse] = useState(null);
  const [error, setError] = React.useState("");
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // Append "discount" to the entered string
    setUrlInput(inputValue);
  };

  const handleProductSearch = () => {
    const urlRegexForHttp = /(https?:\/\/[^\s]+)/;
    const match = urlInput.match(urlRegexForHttp);
    let getHttpUrl = (match && match[0]) ? match[0] : urlInput;
    setUrlInput(prev => (match && match[0]) ? match[0] : prev)
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (urlRegex.test(getHttpUrl)) {
      setLoading(true);
      axios
        .post("/Survey/getProductDetails", {
          productLink: getHttpUrl,
        })
        .then((response) => {
          setLoading(false);
          if (response.data?.product) {
            let productDetail = response.data?.product;
            if (
              !productDetail.title &&
              !productDetail.price &&
              !productDetail.imageUrl &&
              !productDetail.rating
            ) {
              setError(
                "Product not found, Please paste valid amazon product link"
              );
            } else {
              setResarchResponse(response.data?.product);
              setUrlInput(productDetail.productURl);
              setError("");
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          setResarchResponse(null);
          if (error.response?.status === 409) {
            setError("Given Code already exists in the system. Try a new code");
          }
        });
    } else {
      setError("Please enter a valid URL");
    }
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      // Trigger button click when Enter key is pressed
      handleProductSearch();
    }
  };
  return (
    <div>
      <Modal open={isOpen} onClose={toggleLinkModel}>
        <Box sx={style} onKeyDown={handleEnterKey}>
          {/* <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} py={2} my={2}>
              <Typography
                variant="h1"
                gutterBottom
                style={{ fontSize: "1rem", fontWeight: 500 }}
              >
                Paste Your Amazon Product Link
              </Typography>
              <TextField
                label="Enter URL"
                variant="outlined"
                fullWidth
                value={urlInput}
                onChange={handleInputChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleProductSearch}
                sx={{ ml: 2 }}
              >
                Search
              </Button>
            </Grid>
          </Grid> */}
          <Container maxWidth="sm">
            <h2>Amazon India</h2>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  mb: 2,
                }}
              >
                <TextField
                  label="Enter Amazon URL"
                  variant="outlined"
                  fullWidth
                  value={urlInput}
                  onChange={handleInputChange}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleProductSearch}
                  sx={{ ml: 2 }}
                >
                  Search
                </Button>
              </Box>
              {searchResponse && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start",
                    width: "100%",
                    mb: 2,
                    "& > button": { mr: 2 },
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    component="a"
                    href={urlInput + "?&tag=catchdeals0d-21"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Buy Now
                  </Button>
                </Box>
              )}
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress color="secondary" size={64} />
                </div>
              ) : searchResponse ? (
                <Card sx={{ maxWidth: 345 }}>
                  <CardMedia
                  style={{ objectFit: 'contain', height: '140px' }}
                    component="img"
                    image={searchResponse.imageUrl}
                    alt={searchResponse.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {searchResponse.title}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      ${searchResponse.price}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <Rating
                        value={parseFloat(searchResponse.rating)}
                        precision={0.1}
                        readOnly
                      />
                      <Typography variant="body2" color="text.secondary" ml={1}>
                        {searchResponse.rating}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      component="a"
                      href={urlInput + "?&tag=catchdeals0d-21"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Buy Now
                    </Button>
                  </CardContent>
                </Card>
              ) : (
                <></>
              )}
              {error && (
                <Typography variant="body1" color="error">
                  {error}
                </Typography>
              )}
            </Box>
          </Container>
        </Box>
      </Modal>
    </div>
  );
}
