import * as SVGIcons  from "../SVGIcons/SVGIcons";
// constant
const icons = {
  IconLicense: SVGIcons.IconLicense,
  IconShieldLock: SVGIcons.IconShieldLock,
  IconId: SVGIcons.IconId,
  IconBrandBlogger: SVGIcons.IconBrandBlogger,
  IconAddressBook: SVGIcons.IconAddressBook,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: 'utilities',
  title: 'Pages',
  type: 'group',
  children: [
    {
      id: 'blog',
      title: 'Blog',
      type: 'item',
      url: '/blog/react-ssr-seo-react-helmet',
      icon: icons.IconBrandBlogger   ,
      breadcrumbs: false
    },
    {
      id: 'about',
      title: 'About',
      type: 'item',
      url: '/about-us',
      icon: icons.IconId,
      breadcrumbs: false
    },
    {
      id: 'terms',
      title: 'Terms',
      type: 'item',
      url: '/terms',
      icon: icons.IconLicense,
      breadcrumbs: false
    },
    {
      id: 'privacy',
      title: 'Privacy',
      type: 'item',
      url: '/privacypolicy',
      icon: icons.IconShieldLock,
      breadcrumbs: false
    },
    {
      id: 'disclaimer',
      title: 'Disclaimer',
      type: 'item',
      url: '/disclaimer',
      icon: icons.IconShieldLock,
      breadcrumbs: false
    },
    {
      id: 'contactus',
      title: 'Contact Us',
      type: 'item',
      url: '/contact-us',
      icon: icons.IconAddressBook,
      breadcrumbs: false
    }
  ]
};

export default utilities;
